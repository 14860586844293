import {AnalyticsBrowser} from '@segment/analytics-next';
import {directoryStore} from '../stores/DirectoryStore';

export class SegmentAnalytics {

  public static async identify() {
    if (directoryStore.accountDetails) {
      const {
        userEmail,
        userName,
        groupId,
        organizationId,
        instanceId,
        instanceName,
        odpAccountId,
        isOptiUser,
      } = directoryStore.accountDetails;

      await this.analytics.identify(userEmail, {
        groupId,
        organizationId,
        instanceId,
        instanceName,
        name: userName,
        isOptiUser,
        odpAccountId,
      });

      await this.analytics.group(groupId, {
        organizationId,
        instanceId,
        instanceName,
        hasTurnstile: !!organizationId,
        name: instanceName,
        odpAccountId
      });
    }
  }

  public static async page(page: string, properties?: Record<string, any>) {
    if (directoryStore.accountDetails) {
      const {userEmail, groupId} = directoryStore.accountDetails;

      await this.analytics.page(page, {
        userId: userEmail,
        groupId,
        productName: this.productName,
        subProductName: this.productSubName,
        ...properties,
      });
    }
  }

  public static async track(message: string, properties?: Record<string, any>) {
    if (directoryStore.accountDetails) {
      const {
        userEmail,
        userName,
        groupId,
        organizationId,
        instanceId,
        instanceName,
        odpAccountId,
        isOptiUser,
      } = directoryStore.accountDetails;

      await this.analytics.track(message, {
        userId: userEmail,
        name: userName,
        groupId,
        organizationId,
        instanceId,
        instanceName,
        isOptiUser,
        odpAccountId,
        productName: this.productName,
        subProductName: this.productSubName,
        ...properties,
      });
    }
  }
  private static readonly productName = 'Digital Optimization';
  private static readonly productSubName = 'Segmentation';

  private static analytics: AnalyticsBrowser =
    AnalyticsBrowser.load({writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY!});
}
